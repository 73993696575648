import { AnyKeys } from '@lib/misc';

export function hexToRGB(hex: string, alpha?: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export const breakpoints = [576, 768, 992, 1200];

export const spacing = (i: number) => `${i * 8}px`;
export const black = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;
export const teal = (opacity: number) => `rgba(0, 198, 162, ${opacity})`;
export const primaryColor = teal;
export const dangerColor = (opacity: number) => `rgba(245, 34, 45, ${opacity})`;
export const warningColor = (opacity: number) =>
  `rgba(250, 173, 20, ${opacity})`;
export const emailStyle = { fontWeight: 'bold', color: primaryColor(1) } as any;

const colors = {
  grays: {
    10: '#000',
    9: '#040404',
    8: '#404B5A',
    7: '#6E7A8A',
    6: '#929FB1',
    5: '#AEBECD',
    4: '#CFD8DF',
    3: '#E6EAED',
    2: '#EEF1F3',
    1: '#F7FAFC'
  }
};

const light = {
  bg: {
    primary: colors.grays[1],
    secondary: '#fff',
    inset: colors.grays[5],
    input: hexToRGB(colors.grays[5], 0.12)
  },
  text: {
    primary: colors.grays[9],
    secondary: colors.grays[9],
    tertiary: colors.grays[9],
    quarternary: colors.grays[9],
    placeholder: hexToRGB(colors.grays[9], 0.5),
    onPrimary: '#ffffff'
  }
};

export const grayscaleFilter = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
  WebkitFilter: 'grayscale(100%)'
};

const dark = light;

const sizes: FontSizeType = {
  xs: '0.6em',
  sm: '1.0em',
  md: '1.2em',
  lg: '1.8em',
  xl: '2.4em',
  xxl: '3em',
  xxxl: '3.6em',
  xxxxl: '4em'
};

export type FontSizeType = {
  xs: number | string;
  sm: number | string;
  md: number | string;
  lg: number | string;
  xl: number | string;
  xxl: number | string;
  xxxl: number | string;
  xxxxl: number | string;
};

const defaultTheme = {
  fontSizes: sizes,
  lineHeights: {
    body: 1.5,
    heading: 1.3,
    code: 1.6
  },
  breakpoints,
  spacing
};

export type Theme = {
  bg: AnyKeys;
  text: AnyKeys;
  lineHeights: AnyKeys;
  breakpoints: any[];
  spacing: (i: number) => string;
};

export const lightTheme = { ...defaultTheme, ...light };
export const darkTheme = { ...defaultTheme, ...dark };

export const paneShadow = (x: number = 1, opacity: number = 0.8) =>
  `0px ${6 * x}px ${24 * x}px -${15 * x}px rgba(0, 0, 0, ${opacity})`;
export const portableShadow = (shadow: string) => `
  -webkit-box-shadow: ${shadow};
  -moz-box-shadow: ${shadow};
  box-shadow: ${shadow};
`;
