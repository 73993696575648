import React, { forwardRef, useRef } from 'react';
import styled from '@lib/styled';
import { Button } from 'antd';

export default forwardRef((props: any, ref) => {
  const inputRef = useRef(ref);
  const onClick = () => {
    // @ts-ignore
    inputRef?.current?.blur();
    // @ts-ignore
    props.onClick && props.onClick();
  };
  return (
    <StyledButton
      // @ts-ignore
      ref={inputRef}
      {...props}
      onClick={onClick}
      style={{ ...props?.style }}
    />
  );
});

const StyledButton = styled(Button)`
  /* margin-top: 5px;
  margin-bottom: 5px; */
`;
